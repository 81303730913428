import Vue from 'vue';
import VueRouter from 'vue-router';
import App from '../App.vue';

// 导入页面组件
import Index from '@/components/Index.vue';
import UserProtocol from '@/components/UserProtocol.vue'; // 确保文件名和路径正确
// import { component } from 'vue/types/umd';
import fazhuyun from '@/components/fazhuyun.vue';
import yunfawu from '@/components/yunfawu.vue';
import newsPage from '@/components/News.vue';
import documentPage from '@/components/document.vue';
import userContractPage from '@/components/UserContract.vue';
Vue.use(VueRouter);

const routes = [
  { path: '/', component: Index },
  { path: '/userProtocol', component: UserProtocol },
  {path:'/fazhuyun',component: fazhuyun},
  {path:'/yunfawu',component: yunfawu},
  { path: '/news', component: newsPage },
  { path: '/document', component: documentPage },
  { path: '/contract', component: userContractPage }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');

export default router; // 导出路由实例