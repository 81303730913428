<template>

  <div id="app">
    <router-view></router-view>
   </div>
</template>

<script>
// import index from './components/Index.vue'

export default {
  name: 'App',
  mounted() {
     
  
      // this.$router.push('/userProtocol');

  },
  components: {
    
  }
}
</script>

<style lang="scss">
#app {
  display: flex; justify-content: center; align-items: center;
 
// opacity: 0.8;
}
body {
	margin: 0;
	padding: 0;
}
</style>
