<template>
  <div class="index">
    <div class="container">

    <header class="navbar">
      <img src="../assets/guanwang_logo.png" alt="法驻云法务 Logo" class="logo" />
      <nav class="main-nav">
        <ul>
          <li class="nav-item"><a href="#">产品</a>
            <div class="tooltip">目前旗下产品为法驻云以及云法务</div>
          </li>
          <li class="nav-item"><a href="#">解决方案</a>
            
            <div class="tooltip">详细问题解决请咨询客服，我们的服务电话为 (0571) 5612 3889</div>
          
          </li>
          <li class="nav-item"><a href="#">案例</a>
            <div class="tooltip">案例待后续更新</div>
          </li>
          <li>><router-link to="/fazhuyun">法驻云</router-link></li>
          <li><router-link to="/userProtocol">用户协议</router-link></li>
          <li class="nav-item"><a href="#">联系我们</a>
          
            <div class="tooltip">我们的服务电话为 (0571) 5612 3889</div>
          </li>
  

        </ul>
      </nav>
      <button class="download-app-btn"><router-link class="button-font" to="/yunfawu">下载APP</router-link></button>
    </header>
    <router-view></router-view> <!-- 渲染子路由组件 -->
    <section class="hero-section">
      <video autoplay muted loop class="background-video">
        <!-- <source src="../assets/background.mp4" type="video/mp4" /> -->
      </video>
      <div class="hero-content">
        <h1>智能法务，企业护航</h1>
        <p>让法律更简单，让企业更安心</p>
        <button class="cta-button"><router-link class="button-f" to="/yunfawu">立即体验</router-link></button>
      </div>
    </section>

    <section class="features">
      <div class="feature-card">
        <img src="../assets/借用管理.png" alt="合同管理" />
        <h2>智能合同管理</h2>
        <p>高效、自动化的合同管理工具</p>
      </div>
      <div class="feature-card">
        <img src="../assets/法律咨询.png" alt="法务咨询" />
        <h2>法务咨询</h2>
        <p>专业律师在线咨询，随时为您解答</p>
      </div>
      <div class="feature-card">
        <img src="../assets/法律风险管理.png" alt="法律风险分析" />
        <h2>法律风险分析</h2>
        <p>精准识别法律风险，保护您的企业</p>
      </div>
    </section>

    <section class="testimonials">
      <h2>客户评价</h2>
      <div class="testimonial-slider">
        <div class="testimonial">
          <p>"法驻云法务让我们的合同管理变得如此简单，真是太棒了！"</p>
          <span>- 某科技公司 CEO</span>
        </div>
        <!-- 添加更多评价 -->
      </div>
    </section>

    <footer>
      <p>杭州法驻云法务科技有限公司 © 2024 备案号: 浙ICP备2024088934号-1</p>
      <div class="social-links">
        <!-- <a href="#"><img src="../assets/wechat-icon.png" alt="WeChat" /></a>
        <a href="#"><img src="../assets/weibo-icon.png" alt="Weibo" /></a> -->
        <!-- 更多社交媒体图标 -->
      </div>
    </footer>
    
  </div>
  </div>
</template>


<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'index',

}
</script>

<style scoped lang="scss">
/* 全局样式 */
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  color: #fff;
  background-color: #14161d;
  overflow-x: hidden;
}

/* 主容器 */
.container {
  display: flex;
  flex-direction: column;
  // min-height: 100vh; /* 确保容器填满整个视口 */
  width: 100%;
  // background: #000;
}
.index{
  width: 100%;
  display: flex;
  flex-direction: column;
}
/* 顶部导航栏 */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background: rgba(0, 0, 0, 0.8);
  position: relative;
  z-index: 1000;
}
.main-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.main-nav li {
  position: relative;
  margin-right: 20px;
}
.logo {
  width: 75px;
}

.main-nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-nav ul li {
  margin: 0 20px;
}

.main-nav ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s;
}

.main-nav ul li a:hover {
  color: #feba02;
}

.download-app-btn {
  background-color: #feba02;
  color: #000;
  padding: 12px 24px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s;
}
.button-font {
  text-decoration: none;
  color: #000;

}
.button-f{
  text-decoration: none;
  color: #000;

}
.download-app-btn:hover {
  background-color: #d19d01;
}

/* Hero 区域 */
.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  position: relative;
  // background: url('../assets/background-video.mp4') no-repeat center center;
  // background-image: '../asset/guanwang_beijing.png';
  background-image: url('../assets/guanwang_beijing.png'); 
  background-size: cover;
}

.hero-content {
  z-index: 1;
  color: white;
}

.hero-content h1 {
  font-size: 60px;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 28px;
  margin-bottom: 40px;
}

.cta-button {
  background-color: #feba02;
  color: #000;
  padding: 15px 35px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  font-size: 22px;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #d19d01;
}

/* 功能展示 */
.features {
  display: flex;
  justify-content: space-around;
  padding: 80px 40px;
  background-color: #1f2130;
  flex-wrap: wrap;
}

.feature-card {
  text-align: center;
  max-width: 300px;
  margin: 20px;
}

.feature-card img {
  width: 80px;
  margin-bottom: 20px;
}

.feature-card h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #c4c4c4;
}

.feature-card p {
  font-size: 18px;
  color: #c4c4c4;
}

/* 客户评价 */
.testimonials {
  padding: 80px 40px;
  background-color: #14161d;
  color: #c4c4c4;
  text-align: center;
}

.testimonials h2 {
  font-size: 36px;
  margin-bottom: 40px;
}

.testimonial-slider {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.testimonial {
  max-width: 600px;
  margin: 0 20px;
  font-size: 20px;
  color: #c4c4c4;
}

/* 底部 */
footer {
  padding: 20px 40px;
  background-color: #000;
  text-align: center;
}

footer p {
  margin: 0;
  color: #555;
  font-size: 16px;
}

.social-links img {
  width: 30px;
  margin: 0 10px;
  cursor: pointer;
  transition: transform 0.3s;
}

.social-links img:hover {
  transform: scale(1.2);
}

.nav-item .tooltip {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 10;
}

.nav-item:hover .tooltip {
  display: block;
}
</style>

