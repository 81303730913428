<template>
<div class="container">
    <router-link  to="/" >返回首页</router-link>
         <h1>用户隐私政策</h1>
<p>版本发布⽇期： 2024 年 5⽉ 20⽇</p>
<p>  版本更新⽇期： 2024 年 7 ⽉ 17 ⽇</p>
<p> 版本⽣效⽇期： 2024 年 5⽉ 20 ⽇</p>
<p>一、总则</p>
<p><span class="bold">法驻云⾮常重视⽤户个⼈信息的保护。您在使⽤我们的产品或服务
    时，我们可能会收集和使⽤您的相关信息。我们希望通过《法驻云隐私政策》（以下简称“本政策”）向您说明我们在收集和使⽤您相关个⼈信息时对应的处理规则相关事宜，以便更好地保障您的权益。请在使⽤法驻云服务前仔细阅读本《隐私政策》。如果您进⼀步访问并使⽤法驻云，则表⽰您了解并接受了本《隐私政策》中所述的全部内
    容，并同意受其约束。如果您不接受前述任何内容，请停⽌访问或使⽤法驻云。</span></p>
<p>如您对本隐私政策或相关事宜有任何疑问，<span class="bold">您可随时通过客服电话 (0571) 5612 3889 或者发送邮件至fazhuyun@126.com与我们联系。</span></p>
<p>二.权限申请及应用场景</p>
<p><span class="bold">我们对于软件需要在⽤户使⽤设备上获取的权限⾮常严格和克制，在使⽤本应⽤过程中，我们会向您申请使⽤该功能所必须的权限，若您不同意开启该权限，请点击“我不同意”，拒绝该权限的申请仅将影响您对特定产品功能的使⽤。我们在此向您列举产品将可能申请的权限及其使⽤⽬的。</span></p>
<p>（1）设备信息：为了保证基础功能服务的正常运行，向您提供问题诊断服务并进行数据统计，<span class="bold">我们会收集您的MAC地址（收集您的设备信息以便提供更适合的定制化服务，例如向您展示相关程度更高的搜索结果）、设备产商、设备型号、设备语言、设备名称、应用列表信息、操作系统和应用程序版本、分辨率、IMEI信息、OAID信息IP地址、国际移动用户识别码（IMSI）基站信息、设备序列号、应用包名及版本、独立设备标识、访问日期和时间、软硬件特征信息数据</span>，用于鉴别用户身份、记录系统故障和分析系统故障时产生的原因。</p>
<p>（2）<span class="bold">存储权限：读取、存储将应用的图片及其他文件缓存/存储到手机上，以及读取一存储的图片及其他文件。获取SD卡数据用户缓存视频文件</span></p>
<p>（3）我们会<span class="bold">从微信第三方获取您授权共享的账户信息（头像、昵称、地区）。登录后将您的第三方账户与法驻云绑定，使您可以通过第三方账户直接登录并使用我们的产品和服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的个人信息。</span></p>
<p>（4）我们会通过登录、<span class="bold">完善个人信息等方式收集手机号、身份证号、姓名、企业名称、企业地址、职位、电子邮箱等信息</span>，用于商务对接。我们会履行隐私保护协议，<span class="bold">在符合相关法律和法规规定的前提下使用用户个人信息，对用户个人信息进行加密存储、严格保密</span></p>
<p>三.我们收集和使用这些信息的目的</p>
<p><span class="bold">对于我们收集或接收的有关您的信息，我们可能会用于多种目的，包括但不限于：</span></p>
<p><span class="bold">1、将您的公开信息进行汇总、分析以形成报告；</span></p>
<p>2、处理您提交的咨询、订阅或定制服务或让您参与有关我们产品和服务的调查；</p>
<p>3、管理会员资格及会员权益(如认证身份、管理会员账户、组织会员活动、发放会员福利)；</p>
<p>4、<span class="bold">对法驻云进行推广(如对用户信息进行统计并制作反映用户分布及兴趣偏好总体状况的分析报告、制作市场营销图表或其它营销材料，评估我们服务中的广告和其他促销及推广活动的效果，并加以改善)，但我们会对该材料进行去个体识别化处理。</span></p>
<p><span class="bold">5、用于网络安全防范（在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性）；</span></p>
<p><span class="bold">6、应用户特殊要求而提供特定服务时，需要将信息提供给我们的关联公司、第三方或其他用户；</span></p>
<p>7、其他有利于用户和法驻云运营者利益且不违反任何强制性法律法规的情况。</p>
<p><span class="bold">四、我们如何共享、转让、公开披露个人信息</span></p>
<p>（一）<span class="bold">个人信息的共享、转让</span></p>
<p><span class="bold">我们不会向第三方共享、转让您的个人信息，除非经过您本人事先授权同意，或者共享、转让的个人信息是去标识化处理后的信息，且共享第三方无法重新识别此类信息的自然人主体。</span></p>
<p><span class="bold">1.在下列情况下，我们可能会共享的个人信息</span></p>
<p><span class="bold">（1）为实现特定功能而与业务合作伙伴共享</span></p>
<p><span class="bold">为保障[法驻云]相关功能的实现与应⽤安全稳定的运⾏，我们可能会接⼊由第三⽅提供的软件开发包（SDK）实现相关⽬的。我们会对合作⽅获取信息的软件⼯具开发包（SDK）进⾏严格的安全监测，以保护数据安全。我们对接⼊的相关第三⽅SDK在本隐私政策中列出，请注意，第三⽅SDK可能因为其版本升级、策略调整原因导致数据处理类型存在⼀定变化，请以其公⽰的官⽅说明为准。</span></p>
<p><span class="bold">（二）个人信息的公开披露</span></p>
<p><span class="bold">除对违规账号、欺诈行为进行处罚公告时，我们会披露相关账号的必要信息外，我们不会公开披露您的信息，除非遵循国家法律法规规定或者获得您的同意。我们公开披露您的个人信息会采用符合行业内标准的安全保护措施。</span></p>
<p><span class="bold">（三）依法豁免征得同意共享、转让、公开披露的个人信息</span></p>
<p><span class="bold">请您理解，在下列情形中，根据法律法规及国家标准，我们共享、转让、公开披露您的个人信息无需征得您的授权同意</span></p>
<p><span class="bold">（1）与国家安全、国防安全直接相关的；</span></p>
<p><span class="bold">（2）与公共安全、公共卫生、重大公共利益直接相关的；</span></p>
<p><span class="bold">（3）与犯罪侦查、起诉、审判和判决执行直接相关的；</span></p>
<p><span class="bold">（4）出于维护您或其他个人的生命、财产重大合法权益但又很难得到您的同意的；</span></p>
<p><span class="bold">（5）您自行向社会公众公开的个人信息；</span></p>
<p><span class="bold">（6）从合法公开披露的信息中收集个人信息的，包括合法的新闻报道、政府信息公开渠道。</span></p>
<p><span class="bold">五、我们如何保护用户信息</span></p>
<p><span class="bold">1、我们将采取合理的技术措施和其它必要措施，以确保用户信息的安全，并努力防止用户信息泄露、丢失或遭受破坏。</span>其中包括：（1）使用加密技术来确保您的数据在传输过程中保持私密性；（2）审查我们在收集、存储和处理信息方面的做法（包括实体安全措施），以防未经授权的人员访问我们的系统；（4）所有因提供服务而必须接触<span class="bold">个人信息的员工、承包商和代理商</span>，都需要遵守合同中规定的严格保密义务，否则可将被处分或被解约。</p>
<p>2、尽管严格遵守法律法规要求的标准并已经采取了上述合理有效措施，但由于技术的限制以及可能存在的各种恶意手段我们无法控制的原因，法驻云无法始终保证信息绝对安全，我们将尽力确保您提供给我们的个人信息的安全性。因此，<span class="bold">我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码个人信息透露给他人。</span></p>
<p><span class="bold">3.我们会制定应急处理预案，并在发生用户信息安全事件时立即应急预案，努力阻止该安全事件的影响和后果扩大。在不幸发生用户信息安全事件（泄露、丢失）后，我们将按照法律法规的要求，及时向您告知。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</span></p>
<p>六、如何更新、变更、删除您的信息</p>
<p><span class="bold">您在使用我们的产品和服务时，会被要求提供您真实的个人信息，您应当对您所提供信息的真实性、合法性、有效性及完整性负责，并及时更新和维护您的个人信息，以保证信息的真实、合法和有效。您可以随时管理您的个人信息，例如您的个人账户注册信息。您可以自行从法驻云账号中删除您的内容或个人信息(若您之前已创建了会员账户)，或要求我们删除或修改信息，例如您想要删除个人帐户，您可以通过“个人中心-设置-关于我们-注销账户”进行操作，当您注销账号后，我们将依据《中国人民共和国网络安全法》的规定留存你的相关信息，超出法定保存期限后，我们将删除或匿名化处理你的个人信息。</span></p>
<p>七.Cookies及类似技术</p>
<p><span class="bold">1、Cookies是用户访问网站时存放于用户的计算机设备中的小文件，可被用于存储与您的计算机或设备绑定的唯一识别码</span>，<span>以便用户在不同网站的不同浏览进程中被识别为同一个用户。Cookies提供了多种有用的功能，例如可以用来记忆用户的密码或记录用户已经注册过的项目，使用户在网站上的操作更加有效率。</span></p>
<p>2、<span class="bold">我们可能通过Cookies收集用户信息。用户拥有完全的自主权，自行设置浏览器以禁用或限用Cookies。</span>但是，该设置可能导致用户无法登录或使用依赖于Cookie的法驻云的某些服务或功能。</p>
<p>3、我们还可能使用其他类似的技术，例如beacons、flashcookies和HTML5本地存储。<span class="bold">这些技术与Cookies的相似之处在于他们均被存储于用户的设备中，并可用于存储与用户的行为和偏好有关的信息。用户拥有完全的自主权，自行设置以禁用或限用该技术。同样，该设置可能导致用户无法登录或使用法驻云的某些服务或功能。</span></p>
<p>八、未成年人使用我们的服务</p>
<p>我们期望⽗母或监护⼈指导未成年⼈使⽤我们的服务。如<span class="bold">您为未成年⼈，建议请您的⽗母或监护⼈阅读本政策，并在征得您⽗母或监护⼈同意的前提下使⽤我们的服务或向我们提供您的信息。如您的监护⼈不同意您按照本政策使⽤我们的服务或向我们提供信息，请您⽴即终⽌使⽤我们的服务并及时通知我们，以便我们采取相应的措施。法驻云将根据国家相关法律法规的规定保护未成年⼈的个⼈信息的保密性及安全性。</span></p>
<p>九、本政策的适用范围</p>
<p>本隐私政策适用于法驻云及其关联公司提供的所有服务。但是不包括附有独立隐私政策的服务（如第三方提供的产品和服务）。<span class="bold">请您注意，本隐私政策不适用于以下情况：</span></p>
<p><span class="bold">1、通过我们的服务而接入的第三方服务（包括任何第三方网站）收集的信息（本隐私权政策不适用于第三方提供的产品和服务，例如在于法驻云上由第三方提供的产品和服务，以及在我们的服务中链接到的其他网站，这些产品、服务或网站会有独立的隐私政策予以规范，请另行查阅相应的政策规定）；</span></p>
<p><span class="bold">2、通过在我们服务中进行广告服务的其他公司或机构所收集的信息。</span></p>
<p>十、本政策的更新</p>
<p><span class="bold">1.我们可能会不定期修改、更新本隐私政策，有关隐私政策的更新，我们会在法驻云App中以通知公告形式发布，您可以访问法驻云App查询最新版本的隐私政策。对于重大变更，我们会向您发出郑重通知（包括对于某些服务，我们会通过电子邮件发送通知，说明对隐私政策进行的更改）。</span></p>
<p>十一、如何联系我们</p>
<p>当您有任何疑问、意见或建议时，可以通过客服电话<span class="bold"> (0571) 5612 3889</span>与我们联系或者将您的问题发送至法驻云<span class="bold">fazhuyun@126.com</span>或邮寄到如下地址<span class="bold">【收件人：杭州法驻云科技有限公司 地址：杭州市滨江区双城国际1幢306（收）邮编：310000】</span>我们将尽快审核所涉问题，并在验证您的用户身份后的十五天内予以回复。</p>
<p>附件一：关于第三方SDK的说明</p>
<p><span class="bold">我们所接⼊的第三⽅SDK可能会调⽤您的设备权限、获取您的相关信息，以便您在不同的⼿机设备或第三⽅平台上正常使⽤相应功能，我们将这些具有个⼈信息⼿机功能的第三⽅SDK的名称、提供商、所提供的功能/服务隐私政策链接、收集个⼈信息类型</span>，按照实现功能/服务的类别划分说明如下： </p>
<p>一、统计分析类SDK</p>
<p>SDK名称：友盟+</p>
<p>公司名称：友盟同欣（北京）科技有限公司</p>
<p>⽤途⽬的：APP运营统计与分析</p>
<p><span class="bold">收集个⼈信息类型:后台状态下获取设备信息 (IMEI/Mac/AndroidD/IDFA/OPENUDID/GUID/SIM卡IMSI/ICCID)</span></p>
<p>隐私权政策链接: <a href="https://www.umeng.com/page/policy">https://www.umeng.com/page/policy</a></p>
<p>SDK名称：巨量引擎转化SDK</p>
<p>公司名称：北京巨量引擎网络技术有限公司</p>
<p>⽤途⽬的：提供数据统计和分析服务</p>
<p><span class="bold">收集个⼈信息类型:后台状态下获取设备信息 【Android】设备品牌、型号、无线网SSID名称、WIFI路由器MAC地址、设备的mac地址、软件系统版本相关信息、oaid等基础信息</span></p>
<p>隐私权政策链接: <a href="http://event-manager.oceanengine.com/docs/8650/194615"></a>http://event-manager.oceanengine.com/docs/8650/194615</p>
<p>二、支付类SDK（仅安卓用户使用）</p>
<p>SDK名称：微信SDK</p>
<p>公司名称：腾讯科技（深圳）有限公司</p>
<p>隐私政策：<a href="https://pay.weixin.qq.com/index.php/public/apply_sign/protocol_v2"> https://pay.weixin.qq.com/index.php/public/apply_sign/protocol_v2</a></p>
<p>使用场景：使用微信授权登录、完成微信支付、分享时</p>
<p>SDK名称：支付宝支付SDK</p>
<p>公司名称：阿里巴巴</p>
<p>隐私政策：<a href="https://render.alipay.com/p/c/k2cx0tg8">https://render.alipay.com/p/c/k2cx0tg8</a></p>
<p>使用场景：使用使用支付宝支付时</p>
<p><span class="bold">信息获取：安卓（OAID、IMEI、IMSI、Android ID、MAC地址、网络类型、运营商信息、WIFI状态/参数/列表、系统设置、系统属性、设备品牌、设备型号、操作系统、IP地址、网络权限、传感器数据、WIFI接入状态及WLAN热点信息）；iOS（IMEI、IMSI、MAC地址、网络类型、设备型号、操作系统、IP地址、网络权限）</span></p>
<p>使用目的：支付、支付安全及风控</p>
<p>三、语音技术</p>
<p>SDK名称：智能语⾳交互</p>
<p>公司名称：阿⾥云计算有限公司</p>
<p>使⽤⽬的：使⽤语⾳输⼊的时候，实时语⾳识别和合成</p>
<p>隐私政策：<a href="https://terms.alicdn.com/legal-agreement/terms/b_platform_service_agreement/
    20240325163900980/20240325163900980.html">https://terms.alicdn.com/legal-agreement/terms/b_platform_service_agreement/
    20240325163900980/20240325163900980.html</a></p>
<p><span class="bold">涉及个⼈信息：设备型号</span></p>
<p>SDK名称：语⾳合成</p>  



<p>感谢您对“法驻云”应⽤的信任、使⽤！</p>
<p><span class="bold">杭州法驻云科技有限责任公司</span></p>

    </div> 

    </template>

<script>
// import router from '@/router/home-page-router';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'userProtocol',

}
</script>

<style scoped lang="scss">

body {
            font-family: 'Helvetica Neue', Arial, sans-serif;
            margin: 0;
            padding: 0;
            color: #333;
            background-color: #fff;
            font-size: 16px;
            line-height: 1.6;
            width: 100%;
        }
        .container {
            margin-left: 16px;
            margin-right: 16px;
            background-color: #fff;
        }
        h1, h2, h3 {
            color: #444;
        }
        h1 {
            font-size: 24px;
            text-align: center;
        }
        /* h2 {
            font-size: 20px;
            border-bottom: 1px solid #eee;
          
        }
        h3 {
            font-size: 18px;
        } */
        p, ul, li {
            margin-bottom: 10px;
        }
        ul {
            list-style-type: disc;
            padding-left: 20px;
        }
        .bold {
    font-weight: bold;
}
        a {
    word-break: break-all;
    word-wrap: break-word;
}

</style>