<template>
    <div class="container">
           
      <div class="content">
  <!-- {{this.version}} -->
         <!-- <div class="mir" > <iframe :src='mirUrlO'  width="100%" height="100%" frameborder="0"  ></iframe></div> -->
          <!-- <div class="mir" > <iframe ref="mir" :src='mirUrlOl'  width="100%" height="100%" frameborder="0" @load="handleLoad" ></iframe></div> -->
         
               
                
  <!-- <VueOfficeDocx :src="documentUrl1" :style="docxStyles"></VueOfficeDocx> -->
    
          <!-- <a :href="mirUrl">mir</a>
          <a :href="gooleUrl">google</a>
          <a :href='mirUrlO'>miro</a> -->
          <!-- <div class="mir"  v-if="isVisible" > <iframe ref="mir" :src='mirUrlOl'  width="100%" height="100%" frameborder="0" ></iframe></div>
           -->
            <!-- <div class="mir"  v-if="isVisible" > <iframe ref="mir" :src='mirUrlOl'  width="100%" height="100%" frameborder="0" ></iframe></div> -->
           <Pdf class="mir" ref="pdfViewer" :scale="scale"  :src=documentUrl></Pdf>
           <!-- <div class="mir" v-if="lowIsVisible" v-html="htmlContent"></div> -->
           
      </div>
    </div>
  </template>
  
  <script>
  // import moment from 'moment'; // 引入 Moment.js
  // import http from '@/utils/http';
  const axios = require('axios'); 
  // import 'viewerjs/dist/viewer.css';
  // import DocPreview from 'vue-doc-preview'; // 引入 vue-doc-preview 组件
  // import VueOfficeDocx from '@vue-office/docx'
  // import DocxPreview from 'docx-preview';
  import Pdf from '@vue-office/pdf'
  
  
  // import mammoth from "mammoth";
  
  export default {
    name: 'contractPage',
    data() {
      return {
        version:'',
      // images: [
      //     // { src: 'path/to/your-pdf-file.pdf', type: 'pdf' },
      //     { src: 'C:\\Users\\wujian\\Desktop\\用户协议.docx', type: 'word' },
      //   ]
        documentUrl: '',
        gooleUrl:'',
        mirUrl:'',
        mirUrlO:'',
        mirUrlOl:'',
        pdfVisible:false,
      isVisible: true,
      lowIsVisible:true,
        display:'',
        url:'',
        filePath:'',
        scale:'1',
        // scal:'1',
        htmlContent: null,
        docxStyles: {
          background: 'white !important' ,
          padding: '30px 0px',
          position: 'relative',
          boxSizing: 'content-box'
        }
      };
    },
     mounted() {
      
       // 获取 iframe 元素
    //   const iframe = this.$refs.mir;
    // console.log('未施工')
      // 监听 iframe 的 load 事件
  //     iframe.onload = () => {
  //       // 当 iframe 内容加载完成后执行延迟任务
  //       setTimeout(() => {
  //         // 获取按钮元素
  // const button = document.getElementById('ChoiceGroupLabel51-1');
  
  // // 模拟点击按钮
  // button.click();
  //         // 在这里执行你想要延迟执行的任务
  //         console.log('延迟任务已触发！');
  //       }, 200); // 假设延迟 2000 毫秒
  //     };
  
    },
    components: {
      // DocPreview, // 注册 vue-doc-preview 组件
      // VueOfficeDocx,
      Pdf
      // DocxPreview
    },
    created() {
      // this.getBrowserInfo()
  
    //   this.fetchData()
      // this.convertDocxToHtml('http://121.41.52.4:8080/usr/legalconsult/document/APP%E5%BC%80%E5%8F%91%E5%90%88%E5%90%8C/APP%E5%A7%94%E6%89%98%E5%BC%80%E5%8F%91%E5%8D%8F%E8%AE%AE.doc')
        console.log(this.getPath())
      this.documentUrl="https://www.fazhuyun.cn"+this.getPath();


  
    },
    methods: {
  
  
  
  
      //  getBrowserInfo() {
      //   const ua = window.navigator.userAgent;
      //   console.log('fsdfdsfdsfsdfsdfsdfdsfsfsfsdfds')
      //   console.log(ua)
      //   this.version=ua
      //   console.log(this.version)
      //   let browser = "Unknown";
      //   let version = "Unknown";
  
      //   if (ua.indexOf("MSIE") > -1 || ua.indexOf("Trident/") > -1) {
      //       console.log('我是火车网')
      //     browser = "Internet Explorer";
      //     if (/MSIE (\d+\.\d+);/.test(ua)) {
      //       version = RegExp.$1;
      //     } else if (/rv:(\d+\.\d+)/.test(ua)) {
      //       version = RegExp.$1;
      //     }
      //     this.isVisible=false;
      //     this.lowIsVisible=true;
          
      //   } else if (ua.indexOf("Edge/") > -1) {
      //     browser = "Microsoft Edge";
      //     version = ua.split("Edge/")[1];
      //   } else if (ua.indexOf("Chrome/") > -1) {
      //     console.log('我是火车网')
      //     browser = "Google Chrome";
      //     version = ua.split("Chrome/")[1].split(" ")[0];
      //      // 检查 Chrome 版本是否小于等于 96
      //   const majorVersion = parseInt(version.split(".")[0], 10);
      //   console.log('majorVersion========='+majorVersion)
      //   if (majorVersion < 96) {
      //     this.isVisible=this.false
      //     this.lowIsVisible=this.true
      //   }
      //   } else if (ua.indexOf("Firefox/") > -1) {
      //     browser = "Mozilla Firefox";
      //     version = ua.split("Firefox/")[1];
      //   } else if (ua.indexOf("Safari/") > -1 && ua.indexOf("Chrome/") === -1) {
      //     console.log('宽苞小')
      //     browser = "Safari";
      //     // version = ua.split("Version/")[1].split(" ")[0];
      //   }
  
      //   return { browser, version };
      // },
      async fetchData() {
        try {
  
          const id = this.getId();
          // const response = await http.get(`/document/getFileById?fileId=${id}`);
          // const response = await http.post(`/support/convertByUserId?fileId=${id}`);
          // console.log(response)
          const formData = new FormData();
          formData.append('file', id);
  
          const response = await axios.post('https://www.fazhuyun.cn/api/support/convertByUserId', formData, {
            headers: {
                  'Content-Type': 'multipart/form-data',
              },
              responseType: 'blob', // 处理二进制数据
          });
          const blob = new Blob([response.data], { type: 'text/plain' });
          const url = URL.createObjectURL(blob);
          console.log(url)
          this.documentUrl=url
          this.zoomPDF()
  //      var rs= response.data.documentList
  //      this.filePath=rs.filePath
  //      console.log(this.filePath)
  //     if (this.filePath.endsWith('.pdf')) {
  //       // await this.convertDocToPdf(rs.filePath);
  //       this.lowIsVisible=false;
  //       this.isVisible=false;
  //       this.documentUrl ="https://www.fazhuyun.cn"+ rs.filePath;
  //       // this.pdfVisible=true;
        
  //     } else {
        
  //       // this.documentUrl =;
  //       this.convertDocxToHtml("https://www.fazhuyun.cn"+ rs.filePath)
  // // this.mirUrlOl='https://www.fazhuyun.cn/op/view.aspx?src='+this.documentUrl
  
  // // this.mirUrlO='https://view.officeapps.live.com/op/embed.aspx?src='+this.documentUrl,
  // // this.mirUrlOl='https://www.fazhuyun.cn/op/embed.aspx?src='+this.documentUrl,
  // // this.mirUrlOl='https://www.fazhuyun.cn/loleaflet/dist/loleaflet.html?file_path='+this.documentUrl
  
  // // this.gooleUrl='https://docs.google.com/viewer?url='+this.documentUrl
  //       //  this.convertDocxToHtml();
  //           //  console.log(this.filePath)
  //     }
        } catch (error) {
          console.error('请求错误:', error);
        }
      },
  
  async convertDocxToHtml(fileUrl) {
    try {
          // 获取文本流
          const textResponse = await axios.get(fileUrl, {
              responseType: 'arraybuffer',
          });
          // const textData = textResponse.data;
          const formData = new FormData();
          // formData.append('file', new Blob([textData], { type: 'text/plain' }), 'file.txt');
  
          formData.append('file', new Blob([textResponse.data]), 'file.doc'); // 根据文件类型调整文件名
          // formData.append('file', new Blob([textData], { type: 'application/msword' }), 'file.doc');
          // formData.append('file', new Blob([textData], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }), 'file.docx'); // For .docx files
  
          formData.append('outputFormat', 'pdf');
  
          // 发送文本数据到后端
          const pdfResponse = await axios.post('https://www.fazhuyun.cn/api/support/convert', formData, {
            headers: {
                  'Content-Type': 'multipart/form-data',
              },
              responseType: 'blob', // 处理二进制数据
          });
          // 读取 Blob 数据
          const blob = new Blob([pdfResponse.data], { type: 'text/plain' });
  
          // 对文本中的全角空格进行替换
          // const text = await blob.text();
          // const processedText = text.replace(/ /g, ' ');
          // const processedBlob = new Blob([processedText], { type: 'text/plain' });
          // console.log(processedBlob)
          // 创建一个 URL 对象
          const url = URL.createObjectURL(blob);
          console.log(url)
          // 将 URL 设置给 documentUrl
          this.documentUrl = url;
  
      // }
          // // 创建 Blob 对象
          // const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
  
          // // 创建 Blob URL
          // const url = window.URL.createObjectURL(blob);
  
          // // 创建下载链接
          // const link = document.createElement('a');
          // link.href = url;
          // link.setAttribute('download', 'file.pdf'); // 或其他文件名
          // document.body.appendChild(link);
          // link.click();
  
          // // 释放 URL
          // window.URL.revokeObjectURL(url);
      } catch (error) {
          console.error('获取或发送文本数据时出错:', error);
      }
      //   try {
      //     console.log('-------------------------------------------------------')
      //     console.log('我是大头'+this.documentUrl)
      //     const response = await fetch(this.documentUrl);
      //     console.log('快乐大头'+response.data)
      //     const arrayBuffer = await response.arrayBuffer();
      //     console.log('数组大头'+arrayBuffer)
      //     const html = await mammoth.convertToHtml({ arrayBuffer });
      //     this.htmlContent = html.value;
      //   } catch (error) {
      //     console.error('Error converting docx to HTML:', error);
      //   }
      // },
  
      
      // async convertDocToPdf(filePath) {
      // try {
      //     const response = await http.post(`/support/docToDocx?filePath=${filePath}`, {}, {
      //         responseType: 'blob',
      //     });
  
      //     // 读取 Blob 数据
      //     const blob = new Blob([response.data], { type: 'text/plain' });
      //     console.log(blob)
      //     // 对文本中的全角空格进行替换
      //     const text = await blob.text();
      //     const processedText = text.replace(/ /g, ' ');
      //     const processedBlob = new Blob([processedText], { type: 'text/plain' });
      //     console.log(processedBlob)
      //     // 创建一个 URL 对象
      //     const url = URL.createObjectURL(processedBlob);
      //     console.log(url)
      //     // 将 URL 设置给 documentUrl
      //     this.documentUrl = url;
      // } catch (error) {
      //     console.error('请求错误:', error);
      // }
  },
  
  //   async  convertDocToPdf(filePath) {
  //       try {
  
  // const response = await http.post(`/document/docToDocx?filePath=${filePath}`,{},{
  //   responseType: 'blob',
  // })
  // console.log(response)
  // const url= window.URL.createObjectURL(new Blob([response]))
  // this.documentUrl=url
  
  // console.log(this.documentUrl);
  
  //         const response = await http.post(`/document/docToDocx?filePath=${filePath}`);
  
  //     //  var rs= response.data.documentList[0]
  // const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
  // this.documentUrl = URL.createObjectURL(blob); // 创建临时 URL
  // console.log(this.documentUrl);
  //     console.log(this.documentUrl)
      //   } catch (error) {
      //     console.error('请求错误:', error);
      //   }
      // },
  
  
  
          getPath() {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('path') || '';
          },
          zoomPDF() {
        this.$nextTick(() => {
          const pdfElement = this.$refs.pdfViewer.$el;
          pdfElement.style.transform = 'scale(1.2)'; // 根据需要调整缩放值
          pdfElement.style.transformOrigin = 'center'; // 缩放从中间开始
        });
      }
  //     },
  //      handleLoad() {
  //       // iframe 加载完成后执行延迟任务
  //       setTimeout(() => {
  //         // 在这里执行你想要延迟执行的任务
  //         // const button = document.getElementById('ChoiceGroupLabel51-1');
  // // button.click();
  // // // 模拟点击按钮<span unselectable="on" class="cui-ctl-mediumlabel">50%</span>
  // const iframe = this.$refs.mir;
  //   console.log(iframe)
  // const iframeWindow = iframe.contentWindow;
  //   console.log(iframe)
  //   // 使用 iframeWindow.document 来获取 iframe 中的 document 对象
  //   const iframeDocumentC = iframeWindow.document;
  //    console.log(iframeDocumentC)
  
  //   const ic=iframeDocumentC.getElementsByTagName('iframe')
  //  console.log(ic)
  //  const c= ic[0].contentWindow[0]
  //    console.log(c)
  //  const iframeDocument= c.document
  //  console.log(iframeDocument)
  //   // 使用 document.querySelector 或 document.getElementById 来获取 iframe 中的单个元素
  //   const element = iframeDocument.querySelector('.cui-ctl-medium');
  
  //     const element1 = iframeDocument.querySelector('.cui-ctl-mediumlabel');
  //   const t= iframeDocument.getElementsByClassName('cui-ctl-medium')
  //     const t1= iframeDocument.getElementsByClassName('.cui-ctl-mediumlabel')
  // // var elements = document.getElementsByClassName('cui-ctl-medium');
  // console.log('9657')
  // console.log(element)
  // console.log(element1)
  // console.log("+========"+t)
  // console.log(t1)
  //         console.log('iframe 加载完成！');
  //       }, 5000); // 假设延迟 200 毫秒
  //     }
  
  
      // getNewsId() {
      //   const urlParams = new URLSearchParams(window.location.search);
      //   return urlParams.get('id') || '';
      // },
      // formatDate(date) {
      //   if (!date) return '';
  
      //   // 使用 Moment.js 格式化日期
      //   return moment(date).format('YYYY-MM-DD HH:mm');
      // }
    }
  };
  </script>
  
  <style scoped>
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; /* 垂直居中内容 */
    max-width: 100%;
    max-height: 100vh;
    overflow: hidden; /* 确保子元素不超过父元素 */
  }
  .content{
    /* width: 140%; */
     display: flex;
    justify-content: center; /* 居中显示 */
    align-items: center;
    width: 100%;
    max-width: 100%;
    max-height: 100vh
  }
  .mir{
      height: 100vh;
      width: 100%;
      max-width: 100%;
      max-height: 100vh
  }
  
  </style>
  <style>
  
  .docx-wrapper{
   padding: 0px !important;
   width:100% !important;
   margin: 0 !important;
   background: white !important;
  }
  .docx{
     padding: 16px !important;
    width: 100% !important;
  }
  .docx-wrapper>section.docx{
    box-shadow:0 0 0px rgba(226, 60, 60, 0.5) !important;
  
  }
  .body{
    background: white !important;
  }
  .vue-office-pdf-wrapper{
    background: white !important;
  }
  </style>