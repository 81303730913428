<template>
  <div class="container">
    <div class="content">
      <img id="image" v-if="news.imagesList && news.imagesList.length > 0" :src="'https://www.fazhuyun.cn' + news.imagesList[0].imageUrl" alt="Urban Apartments">
      <div id="body_bottom">
        <h1 id="title">{{ news.category }} | {{ news.title }}</h1>
        <div class="meta-info">
          <img src="../assets/time.jpg">
          <span id="date">{{ formatDate(news.publishedDate) }}</span>
          <img id="lookJpg" src="../assets/look.jpg">
          <span id="look">{{ news.views }}</span>
        </div>
        <p id="content">{{ news.content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'; // 引入 Moment.js
import http from '@/utils/http';

export default {
  name: 'NewsDetailPage',
  data() {
    return {
      news: {
        id: '',
        title: '',
        content: '',
        category: '',
        publishedDate: '',
        imagesList: []
      }
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {

        const id = this.getNewsId();
        const response = await http.get(`/news/getNewsById?id=${id}`);
        this.news = response.data.news;
         document.title = this.news.title;
         console.log(this.news.publishedDate)
      } catch (error) {
        console.error('请求错误:', error);
      }
    },
    getNewsId() {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('id') || '';
    },
    formatDate(date) {
      if (!date) return '';
 const utcDate = moment.utc(date);

  // 将月份减去一个月
  utcDate.subtract(1, 'month');
      // 使用 Moment.js 格式化日期
       return utcDate.format('YYYY-MM-DD HH:mm');
    }
  }
};
</script>

<style scoped>

.container {
  width: 100%;
  
}
#body_bottom{
  border-radius: 24px 24px 0px 0px;
  position:absolute;
  top: 94%;
  background: #FFFFFF;
 
}
#lookJpg{
  margin-left: 8px;
}
#body_bottom > *{
  margin-left:16px;
  margin-right:16px;
}
.content {
  position: relative;
  background-color: #fff;
  border-radius: 6px;


}


#image {
  width: 100%;
  max-width: 100%;
}

#title {
  font-size: 24px;
  margin-bottom: 12px;
}

.meta-info {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
  margin-bottom: 12px;

}

.meta-info img {
  width: 20px;
  height: auto;
  margin-right: 4px;
}

#content {
  font-size: 16px;
  line-height: 1.8;
}

@media (max-width: 768px) {



  #title {
    font-size: 20px;
  }

  .meta-info {
    font-size: 12px;
  }

  #content {
    font-size: 14px;
    line-height: 1.6;
  }
 #body_bottom{
  border-radius: 12px 12px 0px 0px;

}
}
</style>
