import { render, staticRenderFns } from "./UserProtocol.vue?vue&type=template&id=51a46eba&scoped=true"
import script from "./UserProtocol.vue?vue&type=script&lang=js"
export * from "./UserProtocol.vue?vue&type=script&lang=js"
import style0 from "./UserProtocol.vue?vue&type=style&index=0&id=51a46eba&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51a46eba",
  null
  
)

export default component.exports