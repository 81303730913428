<template>
  <div class="index">
  <div id="container_first">
    <div class="head_first" >
    <img id="logo" alt="logo" src="../assets/guanwang_logo.png">
     <p id="app_name">法驻云法务app</p> 
     
     <router-link to="/"><img id="app_label_first" alt="app_label" src="../assets/guangwang_jiantou_icon.png" >回到首页</router-link>
    </div>
    <div class="content">
    <div class="content_left">
   <div class="text_image">
   <img  alt="iphone_img" src="../assets/guanwang_wenzi.png">
   </div>
    <div class="download_content">
    
        <img id="iphone" alt="iphone_img" src="../assets/guanwang_iphone.png">
    <img id="android" alt="android_img" src="../assets/guanwang_android.png">

    </div>
    </div>
    <div class="content_right_first" id="home_img_first">
    <img  alt="home_img" src="../assets/app_tupian.png">
    </div>
    </div>
    <div class="bottom">
  <!-- <p id="bottom_lab">杭州法驻云科技有限公司 Copyright 2024 备案号: <a id="bottom_link" href="https://beian.miit.gov.cn/?spm=5176.21213303.J_9220772140.60.24ce2f3dUQCVAh#/Integrated/index">浙ICP备2024088934号-1</a>
</p> -->
    </div>

  </div>
  

  </div>
</template>

<script>
// import router from '@/router/home-page-router';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'index',

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
#container_first{
   font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-image: url('../assets/guanwang_beijing.png');
  /* 设置背景图片填充整个元素 */
  background-size: cover;
  /* 设置背景图片不平铺 */
  background-repeat: no-repeat;
  width: 1920px;
height: 1160px;
position: relative;
// background: linear-gradient( 135deg, #14161D 0%, #3E455B 100%);
border-radius: 0px 0px 0px 0px;
}
#container{
   font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-image: url('../assets/home_bg@1x.png');
  /* 设置背景图片填充整个元素 */
  background-size: cover;
  /* 设置背景图片不平铺 */
  background-repeat: no-repeat;
  width: 1920px;
height: 1160px;
position: relative;
// background: linear-gradient( 135deg, #14161D 0%, #3E455B 100%);
border-radius: 0px 0px 0px 0px;
}
#home_img {
  width: 1202px;
  height: 902px;
  // margin-top:39px;
  position: absolute;
  top: 139px; /* 距离父元素顶部的距离 */
  left: 774px; /* 距离父元素左侧的距离 */
  // margin: 139px 0px 0px 744px;
}

#home_img_first {

  // margin-top:39px;
  position: absolute;
  top: 243px; /* 距离父元素顶部的距离 */
  left: 972px; /* 距离父元素左侧的距离 */
  // margin: 139px 0px 0px 744px;
}
#logo {
  width: 72px;
  height: 72px;
  border-radius: 1px 1px 1px 1px;
  margin: 14px 0 0 260px;
}
#app_name{
  // width: 143px;
  height: 39px;
font-family: PingFang SC, PingFang SC;
font-weight: 500;
font-size: 28px;
color: #FFFFFF;
line-height: 33px;
letter-spacing: 1px;
text-align: left;
font-style: normal;
text-transform: none;
margin-left:16px;
}
.head{
  display: flex;
  height:100px;
  width:100%;
flex-wrap: wrap

  // margin-left:
}


.head_first{
  display: flex;
  height:100px;
  width:100%;
flex-wrap: wrap

  // margin-left:
}
.content{
  display: flex;
  height:980px;
  margin-left:260px;
  
}
.download_content{
  display:flex;
  margin-top:229px;
}
#app_label{
  width: 72px;
height: 40px;
background: #FFFDF5;
border-radius: 24px 24px 24px 24px;
opacity: 0.5;
margin-top:30px;
margin-left:1114px;
// margin: 14px 260px 0 1558px;
}

#app_label_first{
  width: 72px;
height: 40px;
background: #FFFDF5;
border-radius: 24px 24px 24px 24px;
opacity: 0.5;
margin-top:30px;
margin-left:1033px;
// margin: 14px 260px 0 1558px;
}
#EnglishForFazhuyun{
  width: 234px;
height: 34px;
font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
font-weight: normal;
font-size: 24px;
color: #FFFFFF;
line-height: 28px;
text-align: center;
font-style: normal;
text-transform: none;
}
#ChineseForFazhuyun{
  width: 271px;
height: 112px;
font-family: PangMenZhengDao, PangMenZhengDao;
font-weight: 400;
font-size: 98px;
color: #FFFFFF;
line-height: 115px;
text-align: left;
font-style: normal;
text-transform: none;
}
#tip{
  width: 538px;
height: 67px;
font-family: PingFang SC, PingFang SC;
font-weight: 400;
font-size: 48px;
color: #FFFFFF;
line-height: 56px;
letter-spacing: 3px;
text-align: left;
font-style: normal;
text-transform: none;
}
#android{
  width: 298px;
height: 72px;
border-radius: 8px 8px 8px 8px;
border: 1px solid #FEBC01;
margin-left:40px;

}
#iphone{
width: 298px;
height: 72px;
background: #FEBC01;
border-radius: 8px 8px 8px 8px;
}
.content_left{
  display:flex;
  flex-wrap: wrap;
  flex-direction: column
}
.text_image{
  margin-top:196px;
}
#bottom_lab{
width: 584px;
height: 22px;
font-family: PingFang SC, PingFang SC;
font-weight: 500;
font-size: 16px;
color: #FFFFFF;
line-height: 19px;
text-align: left;
font-style: normal;
text-transform: none;
margin-top:29px;
margin-left:260px;
}
#bottom_link{
  color: #FFFFFF;
  text-decoration: none;
}
</style>
