var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index"},[_c('div',{staticClass:"container"},[_c('header',{staticClass:"navbar"},[_c('img',{staticClass:"logo",attrs:{"src":require("../assets/guanwang_logo.png"),"alt":"法驻云法务 Logo"}}),_c('nav',{staticClass:"main-nav"},[_c('ul',[_vm._m(0),_vm._m(1),_vm._m(2),_c('li',[_vm._v(">"),_c('router-link',{attrs:{"to":"/fazhuyun"}},[_vm._v("法驻云")])],1),_c('li',[_c('router-link',{attrs:{"to":"/userProtocol"}},[_vm._v("用户协议")])],1),_vm._m(3)])]),_c('button',{staticClass:"download-app-btn"},[_c('router-link',{staticClass:"button-font",attrs:{"to":"/yunfawu"}},[_vm._v("下载APP")])],1)]),_c('router-view'),_vm._v(" "),_c('section',{staticClass:"hero-section"},[_c('video',{staticClass:"background-video",attrs:{"autoplay":"","muted":"","loop":""},domProps:{"muted":true}}),_c('div',{staticClass:"hero-content"},[_c('h1',[_vm._v("智能法务，企业护航")]),_c('p',[_vm._v("让法律更简单，让企业更安心")]),_c('button',{staticClass:"cta-button"},[_c('router-link',{staticClass:"button-f",attrs:{"to":"/yunfawu"}},[_vm._v("立即体验")])],1)])]),_vm._m(4),_vm._m(5),_vm._m(6)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("产品")]),_c('div',{staticClass:"tooltip"},[_vm._v("目前旗下产品为法驻云以及云法务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("解决方案")]),_c('div',{staticClass:"tooltip"},[_vm._v("详细问题解决请咨询客服，我们的服务电话为 (0571) 5612 3889")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("案例")]),_c('div',{staticClass:"tooltip"},[_vm._v("案例待后续更新")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("联系我们")]),_c('div',{staticClass:"tooltip"},[_vm._v("我们的服务电话为 (0571) 5612 3889")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"features"},[_c('div',{staticClass:"feature-card"},[_c('img',{attrs:{"src":require("../assets/借用管理.png"),"alt":"合同管理"}}),_c('h2',[_vm._v("智能合同管理")]),_c('p',[_vm._v("高效、自动化的合同管理工具")])]),_c('div',{staticClass:"feature-card"},[_c('img',{attrs:{"src":require("../assets/法律咨询.png"),"alt":"法务咨询"}}),_c('h2',[_vm._v("法务咨询")]),_c('p',[_vm._v("专业律师在线咨询，随时为您解答")])]),_c('div',{staticClass:"feature-card"},[_c('img',{attrs:{"src":require("../assets/法律风险管理.png"),"alt":"法律风险分析"}}),_c('h2',[_vm._v("法律风险分析")]),_c('p',[_vm._v("精准识别法律风险，保护您的企业")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"testimonials"},[_c('h2',[_vm._v("客户评价")]),_c('div',{staticClass:"testimonial-slider"},[_c('div',{staticClass:"testimonial"},[_c('p',[_vm._v("\"法驻云法务让我们的合同管理变得如此简单，真是太棒了！\"")]),_c('span',[_vm._v("- 某科技公司 CEO")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('p',[_vm._v("杭州法驻云法务科技有限公司 © 2024 备案号: 浙ICP备2024088934号-1")]),_c('div',{staticClass:"social-links"})])
}]

export { render, staticRenderFns }