import Vue from 'vue'
import App from './App.vue'
// import VueRouter from 'vue-router'
// import indexPage from './components/Index.vue'
// import newsPage from './components/News.vue'
// import documentPage from './components/document.vue'
// import { component } from 'vue/types/umd'
// import userContractPage from './components/UserContract.vue'
import router from './router/home-page-router'
Vue.config.productionTip = false;

new Vue({
 router,
 render: h => h(App)
}).$mount('#app')
